import React, { useState } from 'react';
import classes from './MarketingVideo.module.scss';
import Button from 'components/atoms/Button/Button';
import dynamic from 'next/dynamic';
import PlayIcon from '../../assets/play.svg';
import MarketingVideoModal from './MarketingVideoModal';

const DynamicModalPortal = dynamic(
  () => import('components/templates/ModalPortal/ModalPortal'),
  {},
);

export interface Props {}

const MarketingVideo: React.FC<Props> = () => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const handleVideoModalClick = () => {
    setIsVideoModalOpen(true);
  };

  return (
    <>
      <Button
        buttonType="primary"
        buttonSize="large"
        color="secondary"
        onClick={handleVideoModalClick}
        icon={<PlayIcon />}
        iconPosition="before"
        className={classes.playIntroButton}
      >
        Play Intro
      </Button>

      {isVideoModalOpen && (
        <DynamicModalPortal>
          <MarketingVideoModal onClose={() => setIsVideoModalOpen(false)} />
        </DynamicModalPortal>
      )}
    </>
  );
};

export default MarketingVideo;
