import React from 'react';
import Hero from 'components/organisms/Hero/Hero';
import LandingPageContent from 'components/organisms/LandingPageContent/LandingPageContent';
import HeroSignUpForm from 'components/organisms/Hero/HeroSignUpForm';
import AppStoreLinks from 'components/atoms/AppStoreLinks/AppStoreLinks';
import Txt from 'components/atoms/Txt/Txt';
import classes from './homepage.module.scss';
import { useRouter } from 'next/router';
import MarketingVideo from 'components/organisms/MarketingVideoModal/MarketingVideo';

export interface Props {}

const Homepage: React.FC<Props> = () => {
  const { locale } = useRouter();
  const isEnglishLang = locale?.startsWith('en');

  return (
    <main className="fadedPageBg">
      <Hero
        leftColumnContent={
          <div className={classes.root}>
            <Txt component="h2" className={classes.subtitle}>
              Kill Time, Productively
            </Txt>
            <Txt component="h1">
              Earn <Txt component="strong">real cash</Txt> with paid online surveys
            </Txt>
            <Txt component="p">
              Forget swag or points - withdraw your <Txt component="strong">cash instantly</Txt>{' '}
              with&nbsp;Qmee.
            </Txt>

            {isEnglishLang && <MarketingVideo />}

            <AppStoreLinks showOnMobileOnly={true} />
          </div>
        }
        rightColumnContent={
          <>
            <HeroSignUpForm />
          </>
        }
      />
      <LandingPageContent />
    </main>
  );
};

export default Homepage;
