import { GetStaticProps } from 'next/types';
import Homepage from 'components/pages/Homepage/Homepage';
import { buildPage } from 'utils/pageHelper';

export default buildPage(Homepage, {
  fullPage: true,
  hasMobileSignUpBanner: true,
  metaData: {
    title: 'Qmee – Paid Online Surveys - Shop to earn real cash rewards',
    description:
      'Qmee cash rewards, discounts & surveys. Search, earn and save today and put cash back in your wallet.',
  },
});

export const getStaticProps: GetStaticProps = async (context) => {
  return { props: { isLandingPage: true } };
};
