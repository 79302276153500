import React, { useState } from 'react';
import classes from './MarketingVideoModal.module.scss';
import Overlay from 'components/atoms/Overlay/Overlay';
import styles from './MarketingVideoModal.module.scss';

export interface Props {
  onClose: () => void;
}

const MarketingVideoModal: React.FC<Props> = ({ onClose }) => {
  return (
    <Overlay isVideo showClose onClose={onClose} className={classes.overlay}>
      <div className={styles.videoIframeContainer}>
        <iframe
          className={styles.videoIframe}
          src="https://www.youtube-nocookie.com/embed/J6hxPQcn3PA?si=_7rcX3TvrgeA7548&autoplay=1&modestbranding=1&showinfo=0&controls=1&rel=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
          allowFullScreen
        ></iframe>
      </div>
    </Overlay>
  );
};

export default MarketingVideoModal;
